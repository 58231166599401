import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as DynamicActions from './dynamic.actions';
import * as DynamicSelectors from './dynamic.selectors';
import { DynamicState } from './dynamic.state';
import { UiComponent } from '../models/ui-component.models';
import { Observable } from 'rxjs';
import { UiAction } from '../models/ui-action.models';
import { UiDefinition } from '../models/ui-definition.models';

@Injectable()
export class DynamicFacade {

    constructor(
        private dynamicStore: Store<DynamicState>) {
    }

    public actions$ = this.dynamicStore.select(DynamicSelectors.getActions);
    public localStore$ = this.dynamicStore.select(DynamicSelectors.localStore);
    public sessionStore$ = this.dynamicStore.select(DynamicSelectors.sessionStore);

    InitLocalStore(uiDefinition: UiDefinition) {
        this.dynamicStore.dispatch(DynamicActions.initLocalStore({ uiDefinition: uiDefinition }));
    }

    GetAction(actionName: string): Observable<UiAction> {
        return this.dynamicStore.select(DynamicSelectors.getAction(actionName));
    }

    ExecuteAction(actionName: string) {
        //this.dynamicStore.select(DynamicSelectors.getAction(actionName))
        this.dynamicStore.dispatch(DynamicActions.executeAction({ actionName: actionName }));
    }

    ExecuteActionSuccess(uiAction: UiAction) {
        this.dynamicStore.dispatch(DynamicActions.executeActionSuccess({ uiAction: uiAction }));
    }

    ExecuteStoredProcedure(uiAction: UiAction) {
        this.dynamicStore.dispatch(DynamicActions.executeStoredProcedure({ actionName: uiAction.name, uiAction: uiAction }));
    }

    GeneratePDF(uiAction: UiAction) {
        this.dynamicStore.dispatch(DynamicActions.generatePDF({ actionName: uiAction.name, uiAction: uiAction }));
    }

    GetData(storeName: string, storeDataName: string, storeDataPath: string): Observable<string> {
        return this.dynamicStore.select(DynamicSelectors.getStoreData(storeName, storeDataName, storeDataPath));
    }

    SetData(storeName: string, storeDataName: string, storeDataPath: string, newValue: string) {
        this.dynamicStore.dispatch(DynamicActions.saveDataInStore({storeName: storeName, storeDataName: storeDataName, storeDataPath: storeDataPath, newValue: newValue }))
    }

    RemoveData(storeName: string, storeDataName: string, storeDataPath: string) {
        this.dynamicStore.dispatch(DynamicActions.removeDataInStore({storeName: storeName, storeDataName: storeDataName, storeDataPath: storeDataPath }))
    }

}
