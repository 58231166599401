import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { DynamicBaseClass } from '../dynamic-base-class/dynamic-base-class.component';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-dynamic-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent extends DynamicBaseClass implements OnInit, OnDestroy {

  pdfData$: Observable<string>;
  pdfSafeUrl: SafeResourceUrl | null = null;

  constructor(
    injector: Injector,
    private sanitizer: DomSanitizer
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.pdfData$ = this.GetValueFromConfig();
    this.pdfData$.subscribe(async pdf => {
      if (pdf && pdf.length > 0) {
        const pdfUrl = await fetch(`data:application/pdf;base64,${pdf}`);
        this.pdfSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(pdfUrl.url);
      }
    })
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}

